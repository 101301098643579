.profilePicContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.profilePic {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.aboutSection {
  padding: 2rem 1rem;
  text-align: center;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.description {
  font-size: 1.125rem;
  line-height: 1.5;
  max-width: 800px;
  margin: 0 auto;
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
  .title {
    font-size: 2rem;
  }

  .description {
    font-size: 1rem;
  }
}
