.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: rgba(18, 18, 18, 0.7);
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.logo {
  font-size: 1.2rem;
  color: #ffffff;
}

.logo h1 {
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
}

.logo h2 {
  margin-top: 0;
}

.navLinks {
  display: flex;
  gap: 1rem;
  font-size: 1.1rem;
}

.navLinks a {
  color: #ffffff;
  text-decoration: none;
}

.navLinks a:hover {
  color: #cccccc;
}

.socialLinks a {
  color: #ffffff;
  text-decoration: none;
}

.socialLinks a:hover {
  color: #cccccc;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  gap: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.showNav {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: rgba(18, 18, 18, 0.9);
  padding: 1rem;
  border-radius: 0 0 0 10px;
}

.showNav Link {
  margin-bottom: 0.5rem;
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.name {
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
  color: #ffffff;
}

.role {
  margin-top: 0;
  font-size: 1.2rem;
  color: #ffffff;
}

.navLinks {
  display: flex;
  gap: 1rem;
  font-size: 1.1rem;
  flex-wrap: wrap;
}

@media screen and (max-width: 1050px) {
  .navLinks {
    display: none;
    text-align: center;
  }

  .showNav {
    display: flex;
  }

  .showNav Link {
    margin-left: 0;
  }

  .hamburger {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: 0;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border: none;
  }

  .hamburger span,
  .hamburger span:before,
  .hamburger span:after {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background-color: #fff;
    border-radius: 3px;
    transition: transform 0.2s ease-out;
  }

  .hamburger span:before {
    content: "";
    top: -8px;
  }

  .hamburger span:after {
    content: "";
    bottom: -8px;
  }

  .hamburger span {
    top: 50%;
    transform: translateY(-50%);
  }

  .hamburger.active span {
    background-color: transparent;
  }

  .hamburger.active span:before {
    transform: rotate(45deg);
  }

  .hamburger.active span:after {
    transform: rotate(-45deg);
  }
}

.icon {
  margin-right: 0.5rem;
}
