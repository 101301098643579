.educationSection {
  padding: 2rem 1rem;
  text-align: center;
}

.title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.educationList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.educationItem {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 1rem;
  margin: 1rem;
  text-align: center;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
}

.educationItem h3 {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}

.educationItem p {
  margin-bottom: 0.5rem;
}

.educationImage {
  width: 100%;
  height: auto;
  max-width: 300px;
  max-height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.achievementList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.achievementItem {
  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 1rem;
  margin: 1rem;
  text-align: center;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  padding-bottom: 4rem;
}

.achievementItem h3 {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}

.achievementItem p {
  margin-bottom: 0.5rem;
}

.achievementItem a {
  color: #ffffff;
  text-decoration: none;
}

.achievementItem a:hover {
  color: #cccccc;
}

.certificationBox {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #440253;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 4rem;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.certificationBox:hover {
  background-color: #22012a;
  font-weight: bold;
}

.achievementItem.flip {
  transform: rotateY(180deg);
}

.front,
.back {
  transition: transform 0.6s;
  backface-visibility: hidden;
}

.achievementItem .back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  transition: transform 0.6s;
}

.achievementItem.flip .back p {
  transform: rotateY(180deg);
  backface-visibility: true;
}

.achievementItem.flip .front {
  transform: rotateY(180deg);
}

.achievementItem.flip .back {
  transform: rotateY(0deg);
}

.icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .title {
    font-size: 1.5rem;
  }

  .educationList {
    flex-direction: column;
  }

  .educationItem {
    width: 100%;
    max-width: 400px;
    margin: 0.5rem auto;
  }

  .achievementList {
    flex-direction: column;
  }

  .achievementItem {
    width: 100%;
    max-width: 400px;
    margin: 0.5rem auto;
  }
}
