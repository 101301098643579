@import "./fonts.css";
body {
  margin: 0;
  font-family: "Libre Franklin", "Roboto", sans-serif !important;
  background-color: #121212;
  color: #f0f0f0;
}

a {
  color: #4da6ff;
  text-decoration: none;
}

a:hover {
  color: #87ceff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #e6e6e6;
  margin: 0 0 1rem;
}

p {
  line-height: 1.6;
}

/* Buttons */
.button {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #4da6ff;
  color: #121212;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #87ceff;
}

/* Add any other space theme-related styles here */
