html,
body {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
}

.footer {
  margin-top: auto;
  text-align: center;
  padding: 1rem;
}
