.starryBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/starry-background-image.jpg);
  background-size: cover;
  background-position: center;
  opacity: 0.2;
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .starryBackground {
    background-size: auto 100%;
    background-position: center top;
  }
}
