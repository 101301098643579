.interestsSection {
  padding: 2rem 1rem;
  text-align: center;
}

.title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
}

.interestsGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin: 0;
  padding: 0;
}

.interestCard {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 1rem;
  margin: 1rem;
  text-align: center;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
}

.interestImage {
  width: 100%;
  height: auto;
  max-width: 300px;
  max-height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .title {
    font-size: 2rem;
  }

  .interestsGrid {
    flex-direction: column;
  }

  .interestCard {
    width: 100%;
    max-width: 400px;
    margin: 0.5rem auto;
  }
}
